export const RECEIPTS_HEADERS = `
query receiptsHeaders(
  $filter:  FilterReceiptHeaderInput,
  $orderBy:  ReceiptHeaderOrderInput,
  $searchText: String,
  $skip: Int,
  $take: Int
) {
  receiptsHeaders(
    filter: $filter
    orderBy: $orderBy
    searchText:$searchText      
    skip: $skip
    take: $take
  ) {
    __typename
    ... on ReceiptHeader{
      id
      tenant_id
      tenant{
        id
      }
      status_id
      status{
        id
        color
        name
        translation_key
      }
      receipt_type_id
      receipt_type{
        id
        name
        translation_key
      }
      receipt_letter_id
      receipt_letter{
        id
        name
        translation_key
      }
      payment_method
      receipt_number
      receipt_date
      receipt_file_id
      receipt_file{
        id
        filename
        mimetype
        encoding
      }
      receipt_detail{
        id
        quantity
        unit_price
        subtotal
        subtotal_without_tax
        receipt_line_discount_total
        receipt_line_tax_total
        tax_rate
        discount_price
        discount_percentage
        receipt_detail_item_type_id
        receipt_detail_item_type{
          id
          name
          description
          }
        description
        cost_center_id
        product {
          code
          description
          id
        }
        receipt_tax_type{
          id
          code
          name
          description
          tax_rate
          translation_key
        }
      }
      receipt_total
      receipt_tax_total
      receipt_subtotal
      has_details
      receipt_verification_code
      receipt_verification_due_date
      sender{
        id
        receipt_header_id
        name
        tax_condition
        tax_number
        tax_type {
          name
        }
      }
      customer{
        id
        receipt_header_id
        name
        tax_number
        tax_type {
          name
        }
      }
      due_date
      receipt_source_id
      receipt_source{
        id
        name
        code
        description
      }
      receipt_discount_total
      receipt_period
      receipt_pre_discount_subtotal
      verification_type {
        id
        its
        uts
        dts
        code
        name
        description
        active
        translation_key
      }
      verification_type_id
      from_email
      purchase_order
      related_document_name
      related_document_number
      exchange_rate_quote_value
      exchange_rate_quote_currency
      exchange_rate_quote_description
      receipt_currency_id
      filename
      api_last_retrieved_date
      currency_type {
        id
        its
        uts
        dts
        code
        name
        description
        active
        translation_key
      }
      receipt_header_extension_step {
        receipt_header_id
        extension_step {
          id
          code
          front_intervention
          extension_phase {
            code
            translation_key
          }
          extension_id
          extension {
            code
          }
        }
      }
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;

export const RECEIPT_HEADER = `
query receiptHeader(
  $id: Int!
) {
  receiptHeader(
    id: $id
  ) {
    __typename
    ... on ReceiptHeader{
      id
      tenant_id
      tenant{
        id
      }
      status_id
      status{
        id
        color
        name
        translation_key
      }
      receipt_type_id
      receipt_type{
        id
        name
      }
      receipt_letter_id
      receipt_letter{
        id
        name
        translation_key
      }
      payment_method
      receipt_number
      receipt_date
      receipt_file_id
      receipt_detail{
        id
        quantity
        unit_price
        subtotal
        subtotal_without_tax
        receipt_line_number 
        description
        receipt_line_discount_total
        receipt_line_tax_total
        tax_rate
        discount_percentage
        receipt_tax_type_id
        receipt_detail_item_type_id       
        receipt_header_id
        receipt_detail_item_type{
          id
          name
          description
        }
        receipt_tax_type{
          id
          code
          name
          description
          tax_rate
          translation_key
        }
      }
      receipt_total
      receipt_tax_total
      receipt_subtotal
      has_details
      receipt_verification_code
      receipt_verification_due_date
      sender{
        id
        receipt_header_id
        name
        tax_condition
        tax_type_id
        tax_number
        tax_type {
          name
        }
      }
      customer{
        id
        receipt_header_id
        name
        tax_type_id
        tax_number
        tax_type {
          name
        }
      }
      receipt_currency_id
      due_date
      receipt_source_id
      receipt_source{
        id
        name
        code
        description
      }
      api_last_retrieved_date
      currency_type {
        id
        its
        uts
        dts
        code
        name
        description
        active
        translation_key
      }
      filename
      receipt_discount_total
      receipt_file {
        id
        its
        uts
        dts
        filename
        mimetype
        encoding
        file
      }
      receipt_period
      receipt_pre_discount_subtotal
      verification_type {
        id
        its
        uts
        dts
        code
        name
        description
        active
        translation_key
      }
      verification_type_id
      from_email
      purchase_order
      related_document_name
      related_document_number
      exchange_rate_quote_value
      exchange_rate_quote_currency
      exchange_rate_quote_description
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;

export const STATES = `
query states(
  $filter: FilterStatusInput,
  $orderBy: StatusOrderInput
){
  states(
    filter:$filter, 
    orderBy:$orderBy
  ){
    __typename
    ... on Status{
      id
      code
      name
      description
      active
      translation_key
      color
      tab_title
      translation_key_tab_title
      grouper_id
    }
  }
}
`;
export const RECEIPT_HEADER_TO_INDICATOR = `
query receiptHeaderToIndicator($filter:FilterReceiptHeaderInput!){
  receiptHeaderToIndicator(filter:$filter) {
    __typename
    ... on  ReceiptHeaderToIndicator{
      status_id
      status {
        id
        name
      }
      count
    }
  ...on ResultError {
    status_code
    message
  }
}
}
`;

export const GET_RECEIPT_HEADER_PDF = `
query getReceiptHeaderPdf($id: Int!) {
  getReceiptHeaderPdf(id: $id) {
    __typename
    ... on ReceiptFile {
      id
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
export const RECEIPT_HEADER_COUNT = `
query receiptHeaderCount($filter: FilterReceiptHeaderInput, $orderBy: ReceiptHeaderOrderInput, $searchText: String,) {
  receiptHeaderCount(filter: $filter, orderBy: $orderBy, searchText: $searchText) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
export const EXPORT_RECEIPT_HEADERS = `
query exportReceiptHeaders (
  $format: ExportFormat!, 
  $headersPageInput: HeadersPageInput!,
  $detailsPageInput: DetailsPageInput!,
  $ivaRatesPageInput: IvaRatesPageInput!,
  $othersRatesPageInput: OthersRatesPageInput!,
  $discountRatesPageInput: DiscountRatesPageInput!,
  $orderBy: ReceiptHeaderOrderInput, 
  $filter: FilterReceiptHeaderInput, 
  $searchText: String){  
    exportReceiptHeaders(
    format:$format , 
    headersPageInput: $headersPageInput,
    detailsPageInput: $detailsPageInput,
    ivaRatesPageInput: $ivaRatesPageInput,
    othersRatesPageInput: $othersRatesPageInput,
    discountRatesPageInput: $discountRatesPageInput,
    orderBy: $orderBy, 
    filter: $filter , 
    searchText: $searchText){
    __typename
    ... on ExportResult{
      file
      mimetype
      encoding
      filename
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
} `;

export const EXPORT_RECEIPT_HEADERS_BY_MAIL = `
  query exportReceiptHeadersByEmails (
  $format: ExportFormat!, 
  $headersPageInput: HeadersPageInput!,
  $detailsPageInput: DetailsPageInput!,
  $ivaRatesPageInput: IvaRatesPageInput!,
  $othersRatesPageInput: OthersRatesPageInput!,
  $discountRatesPageInput: DiscountRatesPageInput!,
  $orderBy: ReceiptHeaderOrderInput, 
  $filter: FilterReceiptHeaderInput, 
  $searchText: String,
  $emails:ReceiptDataEmailsInput!,
){  
    exportReceiptHeadersByEmails(
    format:$format , 
    headersPageInput: $headersPageInput,
    detailsPageInput: $detailsPageInput,
    ivaRatesPageInput: $ivaRatesPageInput,
    othersRatesPageInput: $othersRatesPageInput,
    discountRatesPageInput: $discountRatesPageInput,
    orderBy: $orderBy, 
    filter: $filter , 
    searchText: $searchText,
    emails:$emails){
    __typename
    ... on ResponseMessage{
      status
      response
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const EXPORT_RECEIPT_HEADERS_DYNAMIC = `
query exportReceiptHeadersDynamic(
  $searchText: String,
  $filter: FilterReceiptHeaderInput,
  $orderBy: ReceiptHeaderOrderInput,
  $columnsPerPages: ColumnsPerPageInput!,
  $format: ExportFormat!
  $exportMapKey:String
){
  exportReceiptHeadersDynamic(
    searchText:$searchText,
    filter:$filter,
    orderBy:$orderBy,
    columnsPerPages:$columnsPerPages,
    format:$format
    exportMapKey:$exportMapKey
  ){
    __typename
    ...on ExportResult{
      file
      mimetype
      encoding
      filename      
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const RECEIPT_TAX_TYPE = `
query receiptTaxTypes(
  $filter: FilterReceiptTaxTypeInput
  $searchText: String
  $orderBy: ReceiptTaxTypeOrderInput
  $skip: Int
  $take: Int
) {
  receiptTaxTypes(
    filter: $filter
    searchText: $searchText
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    __typename
    ... on ReceiptTaxType {
     	id
      code
      name
      description
      tax_rate
      translation_key
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const RECEIPT_HEADER_WITH_PRODUCT = `
query receiptHeader(
  $id: Int!
) {
  receiptHeader(
    id: $id
  ) {
    __typename
    ... on ReceiptHeader{
      id
      tenant_id
      tenant{
        id
      }
      status_id
      status{
        id
        color
        name
        translation_key
      }
      receipt_type_id
      receipt_type{
        id
        name
      }
      receipt_letter_id
      receipt_letter{
        id
        name
        translation_key
      }
      payment_method
      receipt_number
      receipt_date
      receipt_file_id
      receipt_detail{
        id
        quantity
        unit_price
        subtotal
        subtotal_without_tax
        receipt_line_number 
        description
        receipt_line_discount_total
        receipt_line_tax_total
        tax_rate
        discount_percentage
        receipt_tax_type_id
        receipt_detail_item_type_id       
        receipt_header_id
        receipt_detail_item_type{
          id
          name
          description
        }
        receipt_tax_type{
          id
          code
          name
          description
          tax_rate
          translation_key
        }
        product {
          code
          description
          id
        }
      }
      receipt_total
      receipt_tax_total
      receipt_subtotal
      has_details
      receipt_verification_code
      receipt_verification_due_date
      sender{
        id
        receipt_header_id
        name
        tax_condition
        tax_type_id
        tax_number
        tax_type {
          name
        }
      }
      customer{
        id
        receipt_header_id
        name
        tax_type_id
        tax_number
        tax_type {
          name
        }
      }
      receipt_currency_id
      due_date
      receipt_source_id
      receipt_source{
        id
        name
        code
        description
      }
      api_last_retrieved_date
      currency_type {
        id
        its
        uts
        dts
        code
        name
        description
        active
        translation_key
      }
      filename
      receipt_discount_total
      receipt_file {
        id
        its
        uts
        dts
        filename
        mimetype
        encoding
        file
      }
      receipt_period
      receipt_pre_discount_subtotal
      verification_type {
        id
        its
        uts
        dts
        code
        name
        description
        active
        translation_key
      }
      verification_type_id
      from_email
      purchase_order
      related_document_name
      related_document_number
      exchange_rate_quote_value
      exchange_rate_quote_currency
      exchange_rate_quote_description
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;
