export enum FieldAttributeTenantValue {
  TRUE = 'True',
  FALSE = 'False',
}

export enum SchemaActionTypes {
  SET_SCHEMA = 'SET_SCHEMA',
  TOGGLE_SWITCH = 'TOGGLE_SWITCH',
  RESET_FIELD_TO_DEFAULT = 'RESET_FIELD_TO_DEFAULT',
  RESET_FIELD_TO_PREVIOUS_STATE = 'RESET_FIELD_TO_PREVIOUS_STATE',
  CONFIGURE_DATA_VALIDATION = 'CONFIGURE_DATA_VALIDATION',
  RESET_ALL_FIELDS_TO_DEFAULT = 'RESET_ALL_FIELDS_TO_DEFAULT',
  RESET_ALL_FIELDS_TO_PREVIOUS_STATE = 'RESET_ALL_FIELDS_TO_PREVIOUS_STATE',
}

export enum BusinessSettingsActionTypes {
  SET_BUSINESS_SETTINGS = 'SET_BUSINESS_SETTINGS',
  TOGGLE_SWITCH = 'TOGGLE_SWITCH',
  RESET_SETTING_TO_DEFAULT = 'RESET_SETTING_TO_DEFAULT',
  RESET_SETTING_TO_PREVIOUS_STATE = 'RESET_SETTING_TO_PREVIOUS_STATE',
  RESET_ALL_SETTINGS_TO_DEFAULT = 'RESET_ALL_SETTINGS_TO_DEFAULT',
  RESET_ALL_SETTINGS_TO_PREVIOUS_STATE = 'RESET_ALL_SETTINGS_TO_PREVIOUS_STATE',
}

export enum ReviewerConfigurationActionTypes {
  SET_REVIEWER_CONFIGURATION = 'SET_REVIEWER_CONFIGURATION',
  UPDATE_REVIEWER_CONFIGURATION = 'UPDATE_REVIEWER_CONFIGURATION',
  RESET_CONFIGURATION_TO_DEFAULT = 'RESET_CONFIGURATION_TO_DEFAULT',
  RESET_CONFIGURATION_TO_PREVIOUS_STATE = 'RESET_CONFIGURATION_TO_PREVIOUS_STATE',
}

export enum ReviewerConfigurationOptionCodes {
  REVIEW_NEVER = 'Nunca revisar',
  REVIEW_ALWAYS = 'Siempre revisar',
  REVIEW_BY_CONFIGUATION = 'Revisar según configuración',
}

export enum ReceiptStatusFinished {
  Error = 4,
  Finished = 5,
  Discarded = 8,
  Duplicated = 9,
}
