import { Button, Card, Divider } from 'antd';
import { useContext } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { EnumsValues } from '../../../enums/EnumsValues';
import { IIntegration } from '../../../interfaces/Integration';
import { IPack } from '../../../interfaces/Pack';
import { Authorization } from '../../../shared';
import './CardToSelect.less';

export interface ICardToSelectProps<T> {
  data: T;
  cardHeaderJsx: JSX.Element;
  cardBodyJsx: JSX.Element;
  buttonLabel: string;
  cardDivider: boolean;
  onClick: (value: T) => void;
  isButtonLoading?: number;
  selectedIntegration?: number;
  buttonLabelIfSelected?: string;
  buttonDisabled?: boolean;
}

export function CardToSelect<T extends IPack | IIntegration>(
  props: ICardToSelectProps<T>,
) {
  const { functions, t } = useContext(ContextApp);

  const {
    data,
    cardHeaderJsx,
    cardBodyJsx,
    buttonLabel,
    cardDivider,
    selectedIntegration,
    buttonLabelIfSelected,
    isButtonLoading,
    buttonDisabled,
    onClick,
  } = props;

  const isPack = (data: IPack | IIntegration): data is IPack => {
    return (data as IPack).offer_status !== undefined;
  };

  const isIntegration = (data: IPack | IIntegration): data is IIntegration => {
    return (data as IIntegration).logo !== undefined;
  };

  const selectedCard = data.id === selectedIntegration;

  const hasDiscountOrActiveStatus = (data: IPack | IIntegration): boolean => {
    if (
      (isPack(data) && data.offer_status === 1) ||
      (isIntegration(data) &&
        (selectedCard ||
          EnumsValues.IntegrationStatus.ComingSoon ===
            data.integration_status_id ||
          EnumsValues.IntegrationStatus.Active === data.integration_status_id))
    ) {
      return true;
    }
    return false;
  };

  const isComingSoon =
    isIntegration(data) &&
    EnumsValues.IntegrationStatus.ComingSoon === data.integration_status_id;

  const isActive =
    isIntegration(data) &&
    EnumsValues.IntegrationStatus.Active === data.integration_status_id &&
    !selectedCard;

  const stylesGroup = {
    isActive: {
      borderLeft: '1px solid #8FCF9E',
      borderRight: '1px solid #8FCF9E',
      borderBottom: '1px solid #8FCF9E',
    },
    comingSoon: { border: '2px solid #eeeeee' },
    selectedCard: { border: '2px solid #3BAA61' },
  };

  const getCardStyles = (
    data: T,
    selectedCard: boolean,
    isComingSoon: boolean,
    isActive: boolean,
  ) => {
    let styles = {};

    if (isActive) {
      styles = { ...stylesGroup.isActive };
    } else if (isComingSoon) {
      styles = { ...stylesGroup.comingSoon };
    } else if (selectedCard) {
      styles = { ...stylesGroup.selectedCard };
    } else styles = { border: undefined };

    return {
      ...styles,
      borderRadius: hasDiscountOrActiveStatus(data)
        ? '0px 0px 10px 10px'
        : '10px',
    };
  };

  return (
    <div>
      {hasDiscountOrActiveStatus(data) && cardHeaderJsx}
      <Card
        style={getCardStyles(data, selectedCard, isComingSoon, isActive)}
        bodyStyle={{
          padding: '10px 30px',
        }}
      >
        <div className="CardToSelect">
          {cardBodyJsx}
          {cardDivider && <Divider style={{ margin: 0 }} />}
          <Button
            type={buttonLabel === t('integration.setUp') ? 'primary' : 'ghost'}
            className="button"
            onClick={() => onClick(data)}
            disabled={
              (isButtonLoading && isButtonLoading !== data.id) ||
              (isIntegration(data) &&
                (data.integration_status_id ===
                  EnumsValues.IntegrationStatus.ComingSoon ||
                  (data.integration_status_id ===
                    EnumsValues.IntegrationStatus.Active &&
                    !Authorization.security(
                      functions,
                      EnumsValues.Functions.TenantUpdate,
                    )))) ||
              buttonDisabled
            }
            loading={isButtonLoading === data.id}
            key={data.id}
          >
            {selectedCard ? buttonLabelIfSelected : buttonLabel}
          </Button>
        </div>
      </Card>
    </div>
  );
}
