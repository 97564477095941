import { ReceiptHeaderExtensionStep } from '../interfaces/Extension';
import GraphqlService from '../services/graphql/GraphqlService';
import { Mutation } from '../services/graphql/mutation';

const useExtensionService = () => {
  const { Query, customRequest } = GraphqlService();

  const getReceiptHeaderExtensionStep = async (
    tenant_id: number,
    receipt_header_id: number,
  ): Promise<ReceiptHeaderExtensionStep> => {
    return customRequest({
      query: Query.receiptHeaderExtensionStep,
      variables: {
        tenant_id,
        receipt_header_id,
      },
    });
  };

  const getExtensionDocumentInformation = async (
    receipt_header_id: number,
  ): Promise<any> => {
    return customRequest({
      query: Query.extensionDocumentInformation,
      variables: {
        receipt_header_id,
      },
    });
  };

  const setExtensionDocumentInformation = async (input: {
    receipt_header_id: number;
    data: string;
  }): Promise<any> => {
    return customRequest({
      query: Mutation.setExtensionDocumentInformation,
      variables: {
        input,
      },
    });
  };

  const finishExtensionStep = async (
    tenant_id: number,
    input: {
      id: number;
      receipt_header_id: number;
    },
  ): Promise<any> => {
    return customRequest({
      query: Mutation.executionExtensionStep,
      variables: {
        input,
        tenant_id,
      },
    });
  };

  const queryExternalService = async (
    tenant_id: number,
    receipt_header_id: number,
    input: {
      extension_id: number;
      operation_code: string;
      operation_parameters: string;
    },
  ): Promise<any> => {
    return customRequest({
      query: Query.extensionOperation,
      variables: {
        tenant_id,
        receipt_header_id,
        input,
      },
    });
  };

  return {
    getReceiptHeaderExtensionStep,
    getExtensionDocumentInformation,
    setExtensionDocumentInformation,
    finishExtensionStep,
    queryExternalService,
  };
};

export default useExtensionService;
