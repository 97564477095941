import { Modal } from 'antd';
import { ExtensionStep } from '../../../../interfaces/Extension';
import { useContext, useEffect, useState } from 'react';
import { ContextApp } from '../../../../contexts/ContextApp';
import GraphqlService from '../../../../services/graphql/GraphqlService';
import { EnumsValues } from '../../../../enums/EnumsValues';
import { CloseCircleFilled } from '@ant-design/icons';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import ExtensionWrapper from '../../../../components/common/ExtensionWrapper/ExtensionWrapper';

interface Props {
  isModalOpen: boolean;
  onModalClose: () => void;
  step: ExtensionStep;
  resourceId: number;
}

const ExtensionModal = ({
  isModalOpen,
  onModalClose,
  step,
  resourceId,
}: Props) => {
  const { t } = useContext(ContextApp);
  const modalTitle = t(step?.extension_phase?.translation_key as never, {
    defaultValue: step?.extension_phase?.name,
  });
  return (
    <Modal
      open={isModalOpen}
      onCancel={onModalClose}
      title={modalTitle}
      className="modal-view-receipt"
      width={800}
      bodyStyle={{ minHeight: '75vh' }}
      footer={null}
      style={{ overflow: 'hidden' }}
      key={step?.code}
    >
      <ErrorBoundary fallbackRender={(props) => <FallbackRender {...props} />}>
        <ExtensionWrapper
          step={step}
          resourceId={resourceId}
          onModalClose={onModalClose}
        ></ExtensionWrapper>
      </ErrorBoundary>
    </Modal>
  );
};

export default ExtensionModal;

const FallbackRender = (_props: FallbackProps) => {
  const [whatsappContactLink, setWhatsappContactLink] = useState('');
  const [supportContactLink, setSupportContactLink] = useState('');
  const { customRequest, Query } = GraphqlService();

  const getContactLinks = () => {
    customRequest({
      query: Query.getAppSettingByKey,
      variables: {
        input: {
          key: EnumsValues.SettingNames.supportEmail,
        },
      },
    }).then((response: any) => {
      setSupportContactLink(response.setting_value);
    }).catch(console.error);

    customRequest({
      query: Query.getAppSettingByKey,
      variables: {
        input: {
          key: EnumsValues.SettingNames.ContactLinkOnHelp,
        },
      },
    }).then((response: any) => {
      setWhatsappContactLink(response.setting_value);
    }).catch(console.error);
  }

  useEffect(() => {
    getContactLinks();
  }, []);

  return (
    <div role="alert">
      <CloseCircleFilled style={{color: "red", fontSize: 32, marginBottom: 12}}/>
      <h3 style={{marginBottom: "1rem"}}>Algo salio mal...</h3>
      <p>Estamos trabajando para solucionarlo. Vuelve a intentarlo en unos minutos. Si el problema persiste, contactanos a través de <a href={whatsappContactLink}>WhatsApp</a>, por correo electrónico a <a href={`mailto:${supportContactLink}`}>{supportContactLink}</a>.</p>
    </div>
  );
}
