import { useErrorBoundary } from 'react-error-boundary';

import { ExternalQueryInput, FrontendExtensionProps } from './interfaces';
import { ExtensionStep } from '../../../interfaces/Extension';
import { FinnegansAnexoYDimensiones } from '../../extensions/FINNEGANS/STEP_2/FinnegansAnexoYDimensiones';
import useExtensionService from '../../../hooks/useExtensionService';
import { useContext } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { EnumsValues } from '../../../enums/EnumsValues';
import { notificationContext } from '../../../contexts/NotificationContext';


export enum ExtensionEnum {
  FINNEGANS = 'finnegans',
}

export enum ExtensionStepEnum {
  FinnegansAnexoYDimensiones = 'document_related_and_product_dimensions_selection',
}

type ExtensionRegistryType = Record<
  ExtensionEnum,
  Partial<Record<ExtensionStepEnum, React.FC<any>>>
>;

export const ExtensionRegistry: ExtensionRegistryType = {
  [ExtensionEnum.FINNEGANS]: {
    [ExtensionStepEnum.FinnegansAnexoYDimensiones]: FinnegansAnexoYDimensiones,
  },
};

interface ExtensionWrapperProps {
  step: ExtensionStep;
  resourceId: number;
  onModalClose: () => void;
  backendMock?: any;
}

const ExtensionWrapper: React.FC<ExtensionWrapperProps> = ({
  step,
  resourceId,
  onModalClose,
}) => {
  const { selectedTenantId } = useContext(ContextApp);
  const { openNotification } = useContext(notificationContext);
  const { showBoundary } = useErrorBoundary();
  const {
    getExtensionDocumentInformation,
    setExtensionDocumentInformation,
    finishExtensionStep,
    queryExternalService: QueryExternalService,
  } = useExtensionService();

  const extensionKey = step.extension?.code as ExtensionEnum;
  const stepKey = step.code as ExtensionStepEnum;

  const ExtensionStepToRender = ExtensionRegistry[extensionKey][stepKey];

  if (!ExtensionStepToRender) {
    return <div>Extension no encontrada</div>;
  }

  const extensionProps: FrontendExtensionProps = {
    queryExternalService: async (input: any) => {
      try {
        if (selectedTenantId) {
          const inputParams: ExternalQueryInput = {
            operation_code: input.query,
            operation_parameters: JSON.stringify(input.inputData),
            extension_id: step.extension_id,
          };
          const response = await QueryExternalService(
            selectedTenantId,
            resourceId,
            inputParams,
          );

          return JSON.parse(response.data);
        }
      } catch (error) {
        showBoundary(error);
      }
    },
    // @ts-ignore
    queryInternalService: async (_input: any) => {
      try {
        // TODO
        return false;
      } catch (error) {
        showBoundary(error);
      }
    },
    getExtensionDocumentInformation: async () => {
      try {
        const { data } = await getExtensionDocumentInformation(resourceId);
        if (!data) {
          return {};
        }

        return JSON.parse(data);
      } catch (error: any) {
        if (error.status_code === EnumsValues.ConstNumbers.two) {
          return {};
        }
        showBoundary(error);
      }
    },
    setExtensionDocumentInformation: async (data: any) => {
      try {
        const input = {
          receipt_header_id: resourceId,
          data: JSON.stringify(data),
        };
        return await setExtensionDocumentInformation(input);
      } catch (error) {
        showBoundary(error);
      }
    },
    resourceId,
    finishExtensionStep: async () => {
      try {
        if (selectedTenantId) {
          const finishInput = {
            id: step.id,
            receipt_header_id: resourceId,
          };
          await finishExtensionStep(selectedTenantId, finishInput);
          openNotification({
            msj: "Comprobante actualizado",
            type: 'success',
          });
          onModalClose();
        } else {
          showBoundary(
            new Error('No se pudo obtener el id de la organizacion'),
          );
        }
      } catch (error) {
        showBoundary(error);
      }
    },
  };

  return <ExtensionStepToRender {...extensionProps} />;
};

export default ExtensionWrapper;
