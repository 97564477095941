export const SET_EXTENSION_DOCUMENT_INFORMATION = `
mutation upsertExtensionDocumentInformation(
  $input: UpsertExtensionDocumentInformationInput!
) {
  upsertExtensionDocumentInformation(
    input: $input
  ) {
    __typename
    ... on ExtensionDocumentInformation {
      data
    }
    
    ... on ResultError {
      __typename
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const EXECUTION_EXTENSION_STEP = `
mutation executionExtensionStep(
  $input: ExecutionExtensionStepInput!,
  $tenant_id: Int!
) {
  executionExtensionStep(
    input: $input,
    tenant_id: $tenant_id
  ) {
    __typename
    ... on BooleanResultUnion {
      __typename
      
    }
    
    ... on ResultError {
      __typename
      status_code
      message
      message_translation_key
    }
  }
}
`;
