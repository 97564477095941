import { FrontendExtensionProps } from '../../../common/ExtensionWrapper/interfaces';
import { useEffect, useState, Dispatch, SetStateAction, useRef } from 'react';
import {
  AvailableExternalQueries,
  TipoRol,
} from '../../../common/ExtensionWrapper/query-signs';
import { IReceiptHeader, IReceiptDetail } from '../../../../interfaces/Receipt';
import {
  Button,
  Alert,
  Divider,
  Input,
  Select,
  Spin,
  Steps,
  Tooltip,
} from 'antd';
import './FinnegansAnexoYDimensiones.less';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import ButtonAction from '../../../common/Buttons/ButtonAction';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import useReceiptService from '../../../../hooks/useReceiptService';

interface ExtraData {
  providerCode?: string;
  originDocument?: string;
  products?: ExtensionProduct[];
}

interface ExtensionProduct {
  code: string;
  dimensions: ExtensionDimension[];
  detailId: number;
  description: string;
}

interface ExtensionDimension {
  name: string;
  code: string;
  distribution?: string;
  values: ExtensionDimensionValue[];
}

interface ExtensionDimensionValue {
  name?: string;
  code: string;
  percentage: number;
}

interface RelatedDocument {
  OPERACIONITEMID: number;
  ORGANIZACION: string;
  DOCUMENTO: string;
  COMPROBANTE: string;
  FECHA: string;
  PRODUCTO: string;
  PRODUCTOCODIGO: string;
  CICLOITEMID: string | null;
  BPMPROCESOACTIVIDADID: string | null;
  PENDIENTE: number;
  UNIDAD: string | null;
  PRECIO: number;
  MONEDA: string;
  PARTIDA: string | null;
  DESCRIPCION: string | null;
  FECHAHASTA: string | null;
  FECHAPROXIMOPASO: string | null;
  '@@CLASEVO': string;
  TRANSACCIONID: number;
  IDENTIFICACIONEXTERNA: string;
  SUCURSAL: string;
  TRANSACCIONCATEGORIAID: number;
  NOMBRE: string;
}

interface ExtensionRelatedDocument {
  name: string;
  code: string;
  products: ExtensionRelatedDocumentProduct[];
}

interface ExtensionRelatedDocumentProduct {
  code: string;
  quantity: number;
}

interface Dimension {
  DIMENSIONCODIGO: string;
  DIMENSIONNOMBRE: string;
  DIMENSIONDISTRIBUCIONCODIGO?: string | null;
  DIMENSIONDISTRIBUCIONNOMBRE?: string | null;
}

interface DimensionValue {
  CODIGO: string;
  NOMBRE: string;
  ACTIVO: boolean;
}

interface FinnegansOrdenCompraResponse {
  IdentificacionExterna: string;
  Nombre: string;
  Items: OrdenCompraItem[];
}

interface OrdenCompraItem {
  ProductoCodigo: string;
  ImporteExento: number;
  Cantidad: number;
  vinculacionOrigen: string | null;
  CantidadPendiente: number;
  Descripcion: string | null;
  DimensionDistribucion: FinnegansDimension[];
  Precio: number;
}

export interface FinnegansDimension {
  tipoCalculo: string;
  distribucionCodigo?: string;
  distribucionItems: DistribucionItem[];
  dimensionCodigo: string;
}

export interface DistribucionItem {
  codigo: string;
  porcentaje: number;
}

const validateFormByProduct = (product: ExtensionProduct) => {
  return product.dimensions.every((dimension: ExtensionDimension) => {
    if (
      dimension.values.length > 0 &&
      dimension.values.every((value) => value.percentage > 0 && value.code)
    ) {
      const sum = dimension.values.reduce((acc, value) => {
        acc = acc + value.percentage;
        return acc;
      }, 0);
      return sum === 100;
    }
    return false;
  });
};

export function FinnegansAnexoYDimensiones (props: FrontendExtensionProps) {
  const [error, setError] = useState<any>();
  const [currentStep, setCurrentStep] = useState(0);
  const [dimensions, setDimensions] = useState<Record<string, Dimension[]>>();
  const [dimensionValues, setDimensionValues] =
    useState<Record<string, DimensionValue[]>>();
  const [receiptHeader, setReceiptHeader] = useState<IReceiptHeader>();
  const [availableDocuments, setAvailableDocuments] = useState<
    FinnegansOrdenCompraResponse[]
  >([]);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [extraData, setExtraData] = useState<ExtraData>();
  const changeCounter = useRef<number>(0);
  const lastSavedChangeCounter = useRef<number>(0);

  const currentProduct = extraData?.products?.[currentStep];
  const currentProductIsValid =
    currentProduct && validateFormByProduct(currentProduct);

  /* Card de error */
  const [showStepValidateMessage, setShowStepValidateMessage] = useState(false);

  /* Si el producto actual, pasa a validar, oculto el mensaje */
  useEffect(() => {
    setShowStepValidateMessage(false);
  }, [currentProductIsValid]);

  useEffect(() => {
    changeCounter.current++;
  }, [extraData]);

  const saveUnfinishedExtraData = () => {
    if (extraData && changeCounter.current !== lastSavedChangeCounter.current) {
      saveExtensionData();
      lastSavedChangeCounter.current = changeCounter.current;
    }
  };

  /* Guarda cuando se cambie de paso */
  useEffect(() => {
    saveUnfinishedExtraData();
  }, [currentStep]);

  /* Guarda cuando el producto pasa a ser valido */
  useEffect(() => {
    if (currentProductIsValid) saveUnfinishedExtraData();
  }, [currentProductIsValid]);  

  const { getReceiptHeaderWithProduct } = useReceiptService();

  useEffect(() => {
    /* Intencional para que los errores puedan ser capturados por el Error Boundary */
    if (error) {
      throw new Error(error);
    }
  }, [error])
  

  const initializeData = async () => {
    try {
      // 1. Obtener datos básicos
      const receiptHeaderData = await getReceiptHeaderWithProduct(
        props.resourceId,
      ); // TODO: Por ahora funciona, pero deberia ser un internalQuery

      if (!receiptHeaderData) {
        setError('Receipt header not found');
        return;
      }

      // No deberia pasar ya que la etapa de incompleto fue completada
      if (
        !receiptHeaderData.receipt_detail ||
        !receiptHeaderData.receipt_detail.every(
          (detail: IReceiptDetail) => detail?.product?.code,
        )
      ) {
        setError('Receipt detail not found or product has not code');
        return;
      }

      const extraData = await props.getExtensionDocumentInformation();

      if (!extraData.providerCode) {
        setError(
          'Provider code not found, it should be available in this extension step',
        );
        return;
      }

      // 2. Obtener órdenes de compra disponibles
      const availableDocuments: RelatedDocument[] =
        await props.queryExternalService<
          any,
          AvailableExternalQueries.GET_RELATED_DOCUMENTS
        >({
          query: AvailableExternalQueries.GET_RELATED_DOCUMENTS,
          inputData: {
            providerCode: extraData.providerCode,
            rolType: TipoRol.Destino,
          },
        });

      // 2.1 filtrar documentos para obtener solo las OCs
      const filteredDocuments = availableDocuments.filter(
        (doc: RelatedDocument) => doc?.TRANSACCIONCATEGORIAID === -3,
      );

      // 2.2 transformar data para que sea mas facil intentar obtener los documentos validos
      const groupedDocuments: ExtensionRelatedDocument[] =
        filteredDocuments.reduce(
          (acc: ExtensionRelatedDocument[], doc: RelatedDocument) => {
            const key = doc.IDENTIFICACIONEXTERNA;

            let group = acc.find((item) => item.code === key);

            if (!group) {
              group = {
                code: key,
                name: doc.DOCUMENTO,
                products: [
                  { code: doc.PRODUCTOCODIGO, quantity: doc.PENDIENTE },
                ],
              };
              acc.push(group);
            } else {
              group.products.push({
                code: doc.PRODUCTOCODIGO,
                quantity: doc.PENDIENTE,
              });
            }

            return acc;
          },
          [],
        );

      // 2.3 Buscar documentos validos
      /* 
        este comportamiento puede estar limitado por un requerimiento del Hinojo
        ya que el requerimiento era que se podia usar una OC solo si los productos
        y sus cantidades coinciden exactamente con las de la OC
      */
      const validDocuments = groupedDocuments.filter(
        (OC: ExtensionRelatedDocument) => {
          return OC.products.every(
            (product: ExtensionRelatedDocumentProduct) => {
              return receiptHeaderData.receipt_detail.some(
                (detail: IReceiptDetail) => {
                  return (
                    detail.product!.code === product.code &&
                    detail.quantity === product.quantity
                  );
                },
              );
            },
          );
        },
      );

      // 2.4 obtenemos los datos de las ordenes de compra
      const relatedDocumentsData = await props.queryExternalService<
        FinnegansOrdenCompraResponse[],
        AvailableExternalQueries.GET_BUY_ORDERS
      >({
        query: AvailableExternalQueries.GET_BUY_ORDERS,
        inputData: {
          buyOrderCodes: validDocuments.map(
            (doc: ExtensionRelatedDocument) => doc.code,
          ),
        },
      });

      // 3. Obtener productos únicos y sus códigos
      const uniqueProductCodes = new Set<string>();
      receiptHeaderData.receipt_detail.forEach((detail: IReceiptDetail) => {
        uniqueProductCodes.add(detail.product!.code);
      });

      // 4. Obtener dimensiones para cada producto único
      const dimensionsByProductCode = await props.queryExternalService<
        Record<string, Dimension[]>,
        AvailableExternalQueries.GET_DIMENSIONS
      >({
        query: AvailableExternalQueries.GET_DIMENSIONS,
        inputData: {
          productCodes: Array.from(uniqueProductCodes),
        },
      });

      const uniqueDimensionCodes = new Set<string>();
      Object.keys(dimensionsByProductCode).forEach((productCode) => {
        dimensionsByProductCode[
          productCode as keyof typeof dimensionsByProductCode
        ].forEach((dimension: Dimension) => {
          uniqueDimensionCodes.add(dimension.DIMENSIONCODIGO);
        });
      });

      /* Me fijo los productos que piden dimensiones */
      const productsWithDimensions = Array.from(uniqueProductCodes).filter((productCode) => {
        return dimensionsByProductCode[productCode as keyof typeof dimensionsByProductCode]?.length > 0;
      });

      // 5. Obtener valores para cada dimensión única
      const dimensionValuesByDimensionCode = await props.queryExternalService<
        Record<string, DimensionValue[]>,
        AvailableExternalQueries.GET_DIMENSION_VALUES
      >({
        query: AvailableExternalQueries.GET_DIMENSION_VALUES,
        inputData: {
          dimensionCodes: Array.from(uniqueDimensionCodes),
        },
      });

      /*
        TODO-JOEL: el autocompletado de la orden de compra implica que algunos valores de dimensiones
        podrian autocompletarse tambien. Las ordenes de compra listan productos, y esos productos pueden
        tener dimensiones.
      */
      const formData: ExtraData = {
        ...extraData,
        purchaseOrderCode: extraData?.purchaseOrderCode
          ? extraData.purchaseOrderCode
          : receiptHeader?.purchase_order
          ? relatedDocumentsData.find((oc: FinnegansOrdenCompraResponse) =>
              oc.IdentificacionExterna.split(' ').some(
                (value: any) => value === receiptHeader?.purchase_order,
              ),
            )?.IdentificacionExterna
          : undefined,
        products: receiptHeaderData.receipt_detail.filter((detail: IReceiptDetail) => {
          return detail.product?.code && productsWithDimensions.includes(detail.product.code);
        }).map(
          (detail: IReceiptDetail) => {
            const existedProduct = extraData?.products?.find(
              (product: ExtensionProduct) => product.detailId === detail.id,
            );

            return {
              code: detail.product?.code,
              dimensions: dimensionsByProductCode[detail.product!.code].map(
                (dimension: Dimension) => {
                  return {
                    name: dimension.DIMENSIONNOMBRE,
                    code: dimension.DIMENSIONCODIGO,
                    values:
                      existedProduct && existedProduct.dimensions.length
                        ? existedProduct.dimensions.find(
                            (dim: ExtensionDimension) =>
                              dim.code === dimension.DIMENSIONCODIGO,
                          )?.values || []
                        : [],
                  };
                },
              ),
              detailId: detail.id,
              description: detail.product?.description,
            };
          },
        ),
      };

      setExtraData(formData);
      setDimensions(dimensionsByProductCode);
      setDimensionValues(dimensionValuesByDimensionCode);
      setReceiptHeader(receiptHeaderData);
      setAvailableDocuments(relatedDocumentsData);
    } catch (error) {
      setError(error);
      return;
    }
  };

  const saveExtensionData = async () => {
    return props.setExtensionDocumentInformation(extraData);
  };

  const handleFinishExtension = async () => {
    try {
      await saveExtensionData();
      await props.finishExtensionStep();
    } catch (error) {
      setError(error);
      return;
    }
  };

  useEffect(() => {
    initializeData().catch(error => {
      setError(error);
    });
  }, []);

  useEffect(() => {
    if (extraData && extraData.products)
      setIsValidForm(
        extraData.products.every((product) => {
          return validateFormByProduct(product);
        }),
      );
  }, [extraData]);

  return (
    <div className="extension">
      {extraData && extraData.products ? (
        <>
          <div className="oc-selection">
            <span className="oc-selection__title">
              Orden de compra{' '}
              <span className="oc-selection__title--plus">(Opcional)</span>
            </span>

            {availableDocuments.length > 0 && (
              <Select
                options={availableDocuments.map(
                  (oc: FinnegansOrdenCompraResponse) => ({
                    key: oc.IdentificacionExterna,
                    label: oc.Nombre,
                    value: oc.IdentificacionExterna,
                  }),
                )}
                defaultValue={extraData?.originDocument}
                size="large"
                onChange={(value: string) => {
                  setExtraData({
                    ...extraData,
                    originDocument: value,
                  });
                }}
                allowClear
              />
            )}

            {!availableDocuments.length && (
              <Select disabled placeholder="Sin órdenes de compra disponibles para vincular" />
            )}
          </div>
          {extraData.products.length > 1 && (
            <Stepper
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              products={extraData.products || []}
              extraData={extraData}
              setShowStepValidateMessage={setShowStepValidateMessage}
            />
          )}

          {extraData.products[currentStep] && dimensions && dimensionValues && (
            <ProductStep
              key={currentStep}
              dimensions={dimensions[extraData.products[currentStep].code]}
              dimensionValues={dimensionValues}
              setExtraData={setExtraData}
              extraData={extraData}
              currentProduct={extraData?.products?.[currentStep]}
            />
          )}

          {extraData.products && extraData.products.length === 0 && (
            <div>
              <Alert
                message="No hay productos que requieran dimensiones."
                style={{ borderRadius: 4 }}
              />
            </div>
          )}

          {showStepValidateMessage && (
            <div style={{ marginTop: 20 }}>
              <Alert
                message="Verificar que cada producto tenga al menos un valor en todas sus dimensiones y que la suma de los porcentajes por dimensión sea 100%."
                type="error"
                showIcon
                style={{ borderRadius: 4 }}
              />
            </div>
          )}

          <div style={{ marginTop: 20, textAlign: 'right' }}>
            {extraData.products.length > 1 && (
              <>
                <ButtonSecondary
                  disabled={currentStep === 0}
                  onClick={() => {
                    const pastProductIsValid =
                      currentStep > 0 &&
                      validateFormByProduct(
                        extraData.products![currentStep - 1],
                      );
                    if (currentProductIsValid || pastProductIsValid) {
                      setCurrentStep((prev) => prev - 1);
                    } else {
                      setShowStepValidateMessage(true);
                    }
                  }}
                >
                  Anterior
                </ButtonSecondary>
                <ButtonPrimary
                  style={{ marginLeft: 10 }}
                  disabled={currentStep === extraData.products.length - 1}
                  onClick={() => {
                    const nextProductIsValid =
                      extraData.products &&
                      currentStep < extraData.products.length - 1 &&
                      validateFormByProduct(
                        extraData.products![currentStep + 1],
                      );
                    if (currentProductIsValid || nextProductIsValid) {
                      setCurrentStep((prev) => prev + 1);
                    } else {
                      setShowStepValidateMessage(true);
                    }
                  }}
                >
                  Siguiente
                </ButtonPrimary>
              </>
            )}
          </div>

          <div style={{ textAlign: 'right' }}>
            <Tooltip
              trigger="hover"
              title={
                isValidForm
                  ? undefined
                  : 'Controlar que todas las dimensiones de todos los productos tengan al menos un valor y que la sumatoria de los porcentajes por dimension sea 100'
              }
              placement="topRight"
            >
              <ButtonAction
                style={{ marginLeft: 10 }}
                onClick={handleFinishExtension}
                disabled={!isValidForm}
              >
                Guardar
              </ButtonAction>
            </Tooltip>
          </div>
        </>
      ) : (
        <div className="extension__spin">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

/*
 STEPPER
*/

interface StepperProps {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  setShowStepValidateMessage: Dispatch<SetStateAction<boolean>>;
  products: ExtensionProduct[];
  extraData: ExtraData;
}

export const Stepper = (props: StepperProps) => {
  const {
    currentStep,
    setCurrentStep,
    products,
    extraData,
    setShowStepValidateMessage,
  } = props;
  const { Step } = Steps;

  const getStepIcon = (
    index: number,
    status: 'finish' | 'process' | 'wait',
  ) => {
    if (status === 'finish') {
      // For finished steps, show the step number with selected styles
      return (
        <div className="custom-step-icon custom-step-icon-finish">
          {index + 1}
        </div>
      );
    } else if (status === 'process') {
      // For current step, show a circle
      return (
        <div className="custom-step-icon custom-step-icon-process">
          <div className="custom-step-icon-process-inner"></div>
        </div>
      );
    }
    // For waiting steps, return undefined to use the default icon
    return undefined;
  };

  return (
    <Steps
      className="extension__steps"
      current={currentStep}
      onChange={(step) => {
        const currentProduct = extraData.products![currentStep];
        const currentProductIsValid = validateFormByProduct(currentProduct!);
        const stepProductIsValid =
          extraData.products?.[step] &&
          validateFormByProduct(extraData.products![step]);
        if (currentProductIsValid || stepProductIsValid) {
          setCurrentStep(step);
        } else {
          setShowStepValidateMessage(true);
        }
      }}
    >
      {products?.map((product: ExtensionProduct, index: number) => {
        const status =
          currentStep === index && validateFormByProduct(product)
            ? 'finish'
            : currentStep === index
            ? 'process'
            : validateFormByProduct(product)
            ? 'finish'
            : 'wait';
        return (
          <Step
            key={product.detailId}
            status={status}
            icon={getStepIcon(index, status)}
          />
        );
      })}
    </Steps>
  );
};

/*
 PRODUCT STEP
*/

interface ProductStepProps {
  currentProduct: ExtensionProduct;
  dimensionValues: Record<string, DimensionValue[]>;
  dimensions: Dimension[];
  setExtraData: (extraData: ExtraData) => void;
  extraData: ExtraData;
}

export const ProductStep = (props: ProductStepProps) => {
  const {
    currentProduct,
    dimensionValues,
    dimensions,
    setExtraData,
    extraData,
  } = props;

  const handleAddDistributionValue = (dimensionCode: string) => {
    const newEntry = {
      code: '',
      percentage: 0,
    };

    setExtraData({
      ...extraData,
      products: extraData.products?.map((prod: ExtensionProduct) => ({
        ...prod,
        dimensions: prod.dimensions.map((dim: ExtensionDimension) => ({
          ...dim,
          values:
            prod.detailId === currentProduct.detailId &&
            dim.code === dimensionCode
              ? [...dim.values, newEntry]
              : dim.values,
        })),
      })),
    });
  };

  const handleRemoveDistributionValue = (
    dimensionCode: string,
    index: number,
  ) => {
    setExtraData({
      ...extraData,
      products: extraData.products?.map((prod: ExtensionProduct) => ({
        ...prod,
        dimensions: prod.dimensions.map((dim: ExtensionDimension) => ({
          ...dim,
          values:
            prod.detailId === currentProduct.detailId &&
            dim.code === dimensionCode
              ? dim.values.filter((_value, idx) => idx !== index)
              : dim.values,
        })),
      })),
    });
  };

  const handleUpdateDistributionValue = (
    dimensionCode: string,
    index: number,
    field: keyof ExtensionDimensionValue,
    value: string | number,
  ) => {
    setExtraData({
      ...extraData,
      products: extraData.products?.map((prod: ExtensionProduct) => ({
        ...prod,
        dimensions: prod.dimensions.map((dim: ExtensionDimension) => ({
          ...dim,
          values:
            prod.detailId === currentProduct.detailId &&
            dim.code === dimensionCode
              ? dim.values.map((dimValue, idx) =>
                  idx === index ? { ...dimValue, [field]: value } : dimValue,
                )
              : dim.values,
        })),
      })),
    });
  };

  return (
    <div className="product-step-container">
      <div className="product-step__title">
        <span className="product-step__title--left">
          {currentProduct?.description}
        </span>
        <Divider
          type="vertical"
          style={{
            fontSize: '24px',
            borderLeft: '3.3px solid black',
            borderRadius: '2px',
          }}
        />
        <span className="product-step__title--right">
          {currentProduct?.code}
        </span>
      </div>

      <div className="product-step-dimension">
        {dimensions?.map((dimension: Dimension) => (
          <div className="product-step-dimension__item" key={dimension.DIMENSIONCODIGO}>
            <span className="product-step-dimension__title">
              Dimensión: {dimension.DIMENSIONNOMBRE}
            </span>
            <Divider style={{ margin: '5px 0 15px' }} />

            <DistributionBlock
              availableValues={dimensionValues[dimension.DIMENSIONCODIGO] || []}
              isManual={true}
              dimensionCode={dimension.DIMENSIONCODIGO}
              dimensionValues={
                extraData.products
                  ?.find(
                    (prod: ExtensionProduct) =>
                      prod.detailId === currentProduct.detailId,
                  )
                  ?.dimensions.find(
                    (dim: ExtensionDimension) => dim.code === dimension.DIMENSIONCODIGO,
                  )?.values || []
              }
              handleAddDistributionValue={handleAddDistributionValue}
              handleRemoveDistributionValue={handleRemoveDistributionValue}
              handleUpdateDistributionValue={handleUpdateDistributionValue}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

/////////////////////////////////////////////////////////////
/*
 DISTRIBUTION BLOCK
*/

interface DistributionBlockProps {
  availableValues: DimensionValue[];
  isManual: boolean;
  dimensionCode: string;
  dimensionValues: ExtensionDimensionValue[];
  handleAddDistributionValue: (dimensionCode: string) => void;
  handleRemoveDistributionValue: (dimensionCode: string, idx: number) => void;
  handleUpdateDistributionValue: (
    dimensionCode: string,
    idx: number,
    field: keyof ExtensionDimensionValue,
    value: string | number,
  ) => void;
}

export const DistributionBlock = (props: DistributionBlockProps) => {
  const {
    availableValues,
    isManual,
    dimensionCode,
    dimensionValues,
    handleAddDistributionValue,
    handleRemoveDistributionValue,
    handleUpdateDistributionValue,
  } = props;

  return (
    <div className="distribution-block">
      {dimensionValues.length > 0 && (
        <>
          <div className="distribution-block__header">
            <div>Valor</div>
            <div>Porcentaje</div>
            <div></div>
          </div>

          {dimensionValues.map(
            (dimensionValue: ExtensionDimensionValue, idx: number) => (
              <div
                key={`${dimensionValue.code}-${idx}`}
                className="distribution-block__item"
              >
                <Select
                  defaultValue={dimensionValue.code}
                  onChange={(value) =>
                    handleUpdateDistributionValue(
                      dimensionCode,
                      idx,
                      'code',
                      value,
                    )
                  }
                >
                  {availableValues
                    .filter(
                      (item) =>
                        !dimensionValues.some(
                          (val) => val.code === item.CODIGO,
                        ) || item.CODIGO === dimensionValue.code,
                    )
                    .map((item) => (
                      <Select.Option
                        key={`${dimensionCode}-${item.CODIGO}`}
                        value={item.CODIGO}
                      >
                        {item.NOMBRE}
                      </Select.Option>
                    ))}
                </Select>
                <Input
                  type="number"
                  defaultValue={dimensionValue.percentage}
                  onChange={(e) =>
                    handleUpdateDistributionValue(
                      dimensionCode,
                      idx,
                      'percentage',
                      Number(e.target.value),
                    )
                  }
                />
                <Button
                  className="distribution-block__action-button"
                  onClick={() =>
                    handleRemoveDistributionValue(dimensionCode, idx)
                  }
                  type="link"
                >
                  <MinusCircleOutlined
                    style={{ visibility: isManual ? 'visible' : 'hidden' }}
                    className="distribution-block__action-icon distribution-block__item-content--icon"
                  />
                </Button>
              </div>
            ),
          )}
        </>
      )}
      <Button
        className="distribution-block__action-button"
        type="link"
        onClick={() => handleAddDistributionValue(dimensionCode)}
      >
        <PlusCircleOutlined className="distribution-block__action-icon" />
        <span className="distribution-block__action-label">
          Agregar nuevo valor
        </span>
      </Button>
    </div>
  );
};
