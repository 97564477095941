export const RECEIPT_HEADER_EXTENSION_STEP = `
query receiptHeaderExtensionStep(
  $tenant_id: Int!,
  $receipt_header_id: Int!
) {
  receiptHeaderExtensionStep(
    tenant_id:$tenant_id,
    receipt_header_id: $receipt_header_id
  ) {
    __typename
    ... on ReceiptHeaderExtensionStep {
      id
      receipt_header_id
      extension_step_id
      extension_step {
        __typename
        id
        code
        name
        description
        extension_phase_id
        extension_id
        extension_step_status_id
        translation_key
        front_intervention
        extension_execution_type
        first_step
        its,
        uts
        extension {
          __typename
          id
          its
          uts
          code
          name
          description
          scopes
          extension_status_id
          translation_key
        }
        extension_phase {
          __typename
          id
          its
          uts
          code
          name
          description
          translation_key
        }
      }
    }
    ... on ResultError {
      __typename
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const EXTENSION_DOCUMENT_INFORMATION = `
query extensionDocumentInformation(
  $receipt_header_id: Int!
) {
  extensionDocumentInformation(
    receipt_header_id: $receipt_header_id
  ) {
    __typename
    ... on ExtensionDocumentInformation {
      data
    }
    
    ... on ResultError {
      __typename
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const EXTENSION_OPERATION = `
query extensionOperation(
  $tenant_id: Int!,
  $receipt_header_id: Int,
  $input: ExtensionOperationInput!
) {
  extensionOperation(
    tenant_id:$tenant_id,
    receipt_header_id:$receipt_header_id,
    input:$input
  ) {
    __typename
    ... on  ExtensionOperationResult {
      __typename
      data
    }
    ... on ResultError {
      __typename
      status_code
      message
      message_translation_key
    }
  }
}
`;
