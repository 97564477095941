export type QueryExternalServiceInput<T extends AvailableExternalQueries> = {
  query: T;
  inputData: ExternalQueryParameters[T];
};

export type QueryInternalServiceInput<T extends AvailableInternalQueries> = {
  tenantId: number;
  query: T;
  inputData: InternalQueryParameters[T];
};

// Queries habilitadas
export enum AvailableExternalQueries {
  GET_FINNEGANS_PRODUCT = 'getProductByCode',
  GET_FINNEGANS_PRODUCTS = 'getProductsByCode',
  GET_DIMENSION_VALUES = 'getDimensionValues',
  GET_RELATED_DOCUMENTS = 'getRelatedDocuments',
  GET_DIMENSIONS = 'getDimensions',
  GET_BUY_ORDERS = 'getBuyOrders',
}

export enum AvailableInternalQueries {
  GET_RECEIPT_HEADER = 'getReceipt',
}

export enum TipoRol {
  Origen = 0,
  Destino = 1,
}

// Parámetros para cada query
export interface ExternalQueryParameters {
  [AvailableExternalQueries.GET_FINNEGANS_PRODUCT]: {
    productCode: string;
    // clientId: string; POR BACKEND
    // clientSecret: string; POR BACKEND
  };
  [AvailableExternalQueries.GET_DIMENSION_VALUES]: {
    dimensionCodes: string[];
    // documentCode: string; POR BACKEND
    // clientId: string; POR BACKEND
    // clientSecret: string; POR BACKEND
  };
  [AvailableExternalQueries.GET_FINNEGANS_PRODUCTS]: {
    productCodes: string[];
    // clientId: string; POR BACKEND
    // clientSecret: string; POR BACKEND
  };

  [AvailableExternalQueries.GET_BUY_ORDERS]: {
    buyOrderCodes: string[];
    // clientId: string; POR BACKEND
    // clientSecret: string; POR BACKEND
  };
  [AvailableExternalQueries.GET_RELATED_DOCUMENTS]: {
    providerCode: string;
    rolType: TipoRol;
    // clientId: string; POR BACKEND
    // clientSecret: string; POR BACKEND
    // documentCode: string; POR BACKEND
    // workflowCode: string; POR BACKEND
  };
  [AvailableExternalQueries.GET_DIMENSIONS]: {
    productCodes: string[];
    // clientId: string; POR BACKEND
    // clientSecret: string; POR BACKEND
    // documentCode: string; POR BACKEND
  };
}

export interface InternalQueryParameters {
  [AvailableInternalQueries.GET_RECEIPT_HEADER]: { receiptHeaderId: number };
}
