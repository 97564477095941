import { useEffect } from 'react';

const PIXEL_ID = process.env.REACT_APP_PIXEL_ID || '';

export function useMetaPixel() {
  useEffect(() => {
    if (!PIXEL_ID) {
      console.warn('Meta Pixel no disponible.');
      return;
    }

    if (!(window as any).fbq) {
      const script = document.createElement('script');
      script.innerHTML = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${PIXEL_ID});
          fbq('track', 'PageView');
        `;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      const img = document.createElement('img');
      img.height = 1;
      img.width = 1;
      img.src = `https://www.facebook.com/tr?id=${PIXEL_ID}&ev=PageView&noscript=1`;
      noscript.appendChild(img);
      document.head.appendChild(noscript);
    }
  }, []);

  const trackEvent = (eventName: string, data?: Record<string, any>) => {
    if (
      typeof window !== 'undefined' &&
      typeof (window as any).fbq === 'function' &&
      PIXEL_ID
    ) {
      if (data) {
        (window as any).fbq('track', eventName, data);
      } else {
        (window as any).fbq('track', eventName);
      }
    } else {
      console.warn('Meta Pixel no disponible.');
    }
  };

  return { trackEvent };
}
